import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

/* import { YMaps } from "react-yandex-maps" */

import {
  RecoilRoot,
} from 'recoil';

import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Проверяем наличие tgWebAppData в URL
if (window.location.href.indexOf('tgWebAppData') !== -1) {
  // Создаем элемент <script>
  const script = document.createElement('script');
  script.src = 'https://telegram.org/js/telegram-web-app.js'; // Укажите путь к вашему скрипту
  script.onload = () => {
    // Скрипт загружен, можно запускать React-приложение
    initializeApp();
  };
  script.onerror = () => {
    console.error('Ошибка загрузки скрипта');
    // Можно запустить приложение даже если скрипт не загрузился
    initializeApp();
  };
  // Добавляем скрипт в документ
  document.head.appendChild(script);
} else {
  // Если tgWebAppData нет в URL, сразу запускаем приложение
  initializeApp();
}

function initializeApp() {
  root.render(
    <React.StrictMode>
      <RecoilRoot>
        {/* <YMaps query={{ apikey: YANDEX_MAP_API_KEY }} > */}
          <App />
        {/* </YMaps> */}
      </RecoilRoot>
    </React.StrictMode>
  );
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
