import { atom, selector } from "recoil"

export const filtersAiAssistantTextStateAtom = atom({
  key: "filtersAiAssistantTextStateAtom",
  default: false,
})

export const filtersAiAssistantTextStateSelector = selector({
  key: "filtersAiAssistantTextStateSelector",
  get: ({ get }) => {
    const filtersAiAssistantText = get(filtersAiAssistantTextStateAtom)
    return filtersAiAssistantText
  }
})

