import { atom, selector } from "recoil"

export const filtersAiAssistantMicStateAtom = atom({
  key: "filtersAiAssistantMicStateAtom",
  default: false,
})

export const filtersAiAssistantMicStateSelector = selector({
  key: "filtersAiAssistantMicStateSelector",
  get: ({ get }) => {
    const filtersAiAssistantMic = get(filtersAiAssistantMicStateAtom)
    return filtersAiAssistantMic
  }
})

