import { useMemo, useEffect, useState } from 'react'

import useMediaQuery from '@mui/material/useMediaQuery'

function usePlatformDetect() {

  const matchesUseMediaQuery = useMediaQuery('(min-width:480px)')

  const [platform, setPlatform] = useState<string|null>(null)
  
  const isMobileDevice = useMemo(() => () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent), [navigator.userAgent])
  
  const initData:any = useMemo(() => window?.Telegram?.WebApp?.initData,[window?.Telegram?.WebApp?.initData])

  const handle = () => {

    if( initData ) return setPlatform('bot');

    if(matchesUseMediaQuery) return setPlatform('desktop');

    if(isMobileDevice()) return setPlatform('mobile');
    
    return setPlatform('desktop');
  }

  useEffect(() => {

    handle()

  }, [])

  return platform
}

export default usePlatformDetect

export { usePlatformDetect }

